<template>
  <div class='container'>

      <nav class="nav">
        <ul>
          <li v-for="(nav,index) in navs" :key="index" :class="{'active': nav.active}">
            <router-link v-if="nav.isChild" :to="nav.name">{{ nav.title }}</router-link>
            <a v-else @click="goTo(nav.name)" style="  cursor: pointer;">{{ nav.title }}</a>
            <aside v-if="nav.num">{{ nav.num }}</aside>
          </li>
        </ul>
      </nav>
      <div class="main">
      <router-view></router-view>
      </div>
    
  </div>
</template>

<script>
export default {
  name: 'work-index',
  data () {
    return {
      navs: [
        {
          title: "公司通讯录",
          name: 'address-address-gstxl',
          isChild: 1,
          num: 0,
          active: false
        },
        {
          title: "主管",
          name: 'address-address-zg',
          isChild: 1,
          num: 0,
          active: false
        },
        {
          title: "督察",
          name: 'address-address-du',
          isChild: 1,
          num: 0,
          active: false
        },
        {
          title: "客服",
          name: 'address-address-kf',
          isChild: 1,
          num: 0,
          active: false
        },
       
      ]
    }
  },
  created () {
    console.log(this.$route.name);

  },
  methods: {
    goTo (name) {
      let routeData = this.$router.resolve({ name: name, });
      window.open(routeData.href, '_blank');

    },


  }
}
</script>

<style lang="scss" scoped>

.container{
  display: flex;
}
.nav {
  width: 150px;
  min-height: calc(100vh - 160px);
  border-radius: 6px;
  margin-right: 20px;
  overflow: hidden;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  ul {
    li {
      position: relative;
      a {
        padding: 0 24px;
        line-height: 60px;
        // line-height: 72px;
        display: block;
        // font-size: 22px;
        &.router-link-active {
          background-color: #4c71db;
          color: #fff;
        }
      }
      aside {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        right: 0;
        color: #fff;
        background-color: #ff4e4e;
        border-radius: 10px;
        line-height: 20px;
        font-size: 12px;
        text-align: center;
      }
    }
  }
}
.main {
  width: 980px;
  border-radius: 6px;
  padding: 0px;
  background: #ffffff;
}
</style>